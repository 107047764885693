import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataUsuarioService {
  constructor() {}

  set empresaCodigo(codigo: number) {
    localStorage.setItem('empresa_codigo', codigo? codigo.toString() : '');
  }

  get empresaCodigo(): number {
    return Number(localStorage.getItem('empresa_codigo'));
  }
}
